import React, { useEffect } from "react";


const Custom404: React.FunctionComponent = () => {
  useEffect(() => {
    window.location.href = "/";
  });
  return null;
};

export default Custom404;